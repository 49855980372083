<template>
  <div class="page">
    <div class="header" v-if="showHeader">
      <slot name="header">
        <navheader @showFeedback="showFeedback"></navheader>
      </slot>
    </div>
    <el-dialog title="客服和帮助" :visible.sync="feedBackShow">
      <el-row>
        <div>如果您遇到问题，可以使用<span style="font-weight: bold; color: limegreen">微信</span>扫码下面的二维码，联系企业客服</div>
        <div style="text-align: center; margin: 10px 0;"><img height="160" src="src/img/weixin_service_qrcode.png"/></div>
      </el-row>
      <div>
        <div style="margin: 20px 0 10px;">如果您有更好的意见和建议，请输入反馈</div>
        <el-input type="textarea" rows="6" v-model="comment"></el-input>
      </div>
      <div v-if="isLogin()" style="margin: 0.2rem 0 1rem 0; text-align:center;">
        <!-- <span><router-link to="/login">点击查看之前的反馈记录</router-link></span> -->
      </div>
      <div v-else style="margin-bottom: 1rem;">
        <span style="font-size:small"><i class="fa fa-info-circle"></i> 若想跟踪反馈意见的处理进度，请先
          <router-link to="/login" @click="feedBackShow=false">登录</router-link>后再提交</span>
      </div>

      <div style="display:flex; justify-content:space-around;">
        <el-button @click="feedBackShow=false">取消</el-button>
        <el-button type="primary" @click="submit()">提交</el-button>
      </div>
    </el-dialog>
    <div class="main" v-loading="$store.state.inLoading">
      <slot>main content</slot>
    </div>
    <div v-if="showFooter">
      <slot name='footer'>
        signcheck.online
      </slot>
    </div>
  </div>
</template>

<script>
import navheader from './header.vue'
// import loading from 'element-ui'
import { Dialog, Input, Button } from 'element-ui';

export default {
  name: 'layout',
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    navheader,
    // loading,
    'el-dialog': Dialog,
    'el-button': Button,
    'el-input': Input
  },
  data() {
    return {
      comment: '',
      contactPhone: '',
      feedBackShow: false,
      elementLoaded: false
    }
  },
  methods: {
    showFeedback(path) {
      console.log("feedback at ", path)
      this.feedBackShow = true;
    },
    async submit() {
      this.feedBackShow = false
      try {
        await this.$axios({
          method: 'POST',
          url: '/feedback',
          params: {
            comment: this.comment
          }
        })
        if (this.isLogin()) {
          window.alert("提交成功，处理结果后续会通过短信提醒你")
        } else {
          this.$message.success("提交成功，谢谢您的反馈意见")
        }
      } catch (err) {
        this.$message.warning(err.response?.data?.msg)
      }
    },
    isLogin() {
      const login = window.localStorage.getItem('accessToken') != null;
      return login
    }
  }
}
</script>
<style>
.page {
  min-height: 100vh;
  background-color: var(--bgColor);
  font-family: Serif;
}
.header {
  padding: 8px 3vw;
  background-color: var(--navColor); /*#42B1DD;*/  /*#009ad6;*/
  opacity: 99%;
  font-size: 2rem;
  position: sticky;
  top:0;
  z-index:9;
  height: 54px;
  /*border-bottom: dashed 1px var(--navColorShadow);*/
  box-shadow: 0px 1px 1px var(--navColorShadow);
}
.main {
  /*padding: 0.5rem;*/
  max-width: 992px;
  margin: auto;
}
.container {
  padding: 0rem 0.5rem;
}
.footer {
  text-align: center;
}
#collapsibleNavbar ul li {
  margin: 0rem 0.5rem;
}
.clickable:hover {
  cursor: pointer;
}
/*.btn-info {*/
/*  background-color: var(--mainColor);*/
/*  border-color: var(--mainColor);*/
/*}*/
.btn-light:hover {
  background-color: #aaa;
}
.card-header {
  background: none;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-body {
  /*padding: 0.5rem; 设置太小会导致页面整体能左右滑动*/
}
.el-table .cell {
  padding: 0 5px;
}
.el-message-box {
  width: 100vw;
}
.el-input__inner {
  padding: 0.5rem;
}
.el-dialog {
  min-width: 360px;
}
.emp {
  font-weight: bold;
}
img {
  max-width: 100%;
}
a {
  color: var(--mainColor)
}
a:hover {
  color: var(--mainColorLight)
}

</style>
