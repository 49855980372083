<template>
  <nav style="max-width: 992px; margin: auto" class="navbar navbar-expand-sm">
    <router-link to="/" class="secondNav">
      <img height="32" src="src/img/logo.svg"/>
      <img style="position: relative; left: -10px;" height="32" src="src/img/logo-right.png" />
    </router-link>
<!--    <router-link to="/contracts/new" class="secondNav">-->
<!--      <i class="fa fa-plus" aria-hidden="true">创建</i>-->
<!--    </router-link>-->

    <div style="display: inline-flex">
    <div class="secondNav" style="margin-right: 20px; cursor: pointer" @click="popupFeed">
      客服
    </div>

    <router-link to="/login/" class="secondNav">
      <div>管理</div>
    </router-link>
    </div>
    <!-- button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button -->
    <!-- <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/async">async</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/draw">draw</router-link>
        </li>
      </ul>
    </div> -->

  </nav>
</template>
<script>


export default {
  data () {
    return {
      comment: '',
      showFeedback: false
    }
  },
  methods: {
    popupFeed() {
      this.$emit("showFeedback", this.$route.path)
    }
  },
  async mounted () {
  }
}

</script>
<style>
.secondNav {
  font-size:  1.2rem;
  color: var(--mainColor);
}
.navbar {
  justify-content:space-between;
  padding: 3px 6px;
}

</style>
