// 1. 定义 (路由) 组件。
// const Foo = { template: '<div>foo</div>' }
// const Bar = { template: '<div>bar</div>' }

const Home = () => import('./home.vue')
const Async = () => import('./async.vue')
const Draw = () => import('./draw.vue')
const ContractList = () => import('./components/contracts/list.vue')
const ContractNew = () => import('./components/contracts/new.vue')
const ContractShow = () => import('./components/contracts/show.vue')
const TradeShow = () => import('./components/trades/show.vue')
const TradeList = () => import('./components/trades/list.vue')
const Login = () => import('./components/login.vue')

// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
const routes = [
  { path: '/', component: Home },
  { path: '/async', component: Async },
  { path: '/draw', component: Draw },
  { path: '/login', component: Login },
  { path: '/contracts', component: ContractList },
  { path: '/contracts/new', component: ContractNew },
  { path: '/contracts/show/:Id', component: ContractShow, props: true },
  { path: '/trades', component: TradeList, props: true },
  { path: '/trades/show/:Id', component: TradeShow, props: true }
]

export { routes }
