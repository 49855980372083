import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
// import { Loading } from 'element-ui'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    inLoading: false
  },
  mutations: {
    showLoading (state) {
      state.inLoading = true
    },
    hideLoading (state) {
      state.inLoading = false
    }
  }
})

const axiosInit = () => {
  axios.defaults.baseURL = window.global_config.backend_url // 配置axios请求的地址
  axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
  // axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
  axios.defaults.crossDomain = true
  axios.defaults.withCredentials = true  // 设置cross跨域 并设置访问权限 允许跨域携带cookie信息

  // 配置发送请求前的拦截器 可以设置token信息
  axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem('accessToken')
    if (token) {
      const tokenContent = token.split('.')[1]
      if (tokenContent) {
        const tokenObj = JSON.parse(atob(tokenContent))
        if (tokenObj.exp > new Date().getTime() / 1000) {
          config.headers.accessToken = token
        }
      }
    }
    store.commit('showLoading')
    return config
  }, error => {
    store.commit('hideLoading')
    return Promise.reject(error)
  })

  // 配置响应拦截器
  axios.interceptors.response.use(res => {
    store.commit('hideLoading')
    // 这里面写所需要的代码
    return Promise.resolve(res)
  }, error => {
    store.commit('hideLoading')
    if (error.response.status === 401) {
      window.localStorage.removeItem('accessToken')
      const r = window.confirm('你未登录或会话已过期，请登录')
      if (r === true) {
        window.location.href = '#/login'
      }
    }
    return Promise.reject(error)
  })
  return axios
}

export { axiosInit, store }
