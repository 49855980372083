import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import ElmentUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'font-awesome/css/font-awesome.min.css'

import { routes } from './routes.js'
import Layout from '@/components/layout'
// import { getData } from './util.js'
import { axiosInit, store } from './init/axios-init'

import './style/common.scss'

if (navigator.userAgent.indexOf('AlipayClient') > -1) {
  document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>')
  document.writeln('<script src="https://cdn.jsdelivr.net/npm/eruda"' + '>' + '<' + '/' + 'script>')
}

Vue.prototype.$axios = axiosInit()
Vue.use(VueRouter)
Vue.use(Vuex)

// import('element-ui').then(ElementUI => {
//   Vue.use(ElementUI)
// }).catch((error) => {
//   return 'loading element-ui failed: ' + error
// })
Vue.use(ElmentUI)

Vue.component(Layout.name, Layout)

const router = new VueRouter({
  routes
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router: router,
  template: '<router-view/>'
})
